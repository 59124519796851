import axios from "axios";
var omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL;
var securityApiUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL;

export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export function getClientsForUser(data)
{
  return axios.get(securityApiUrlPrefix+'/api/identity/getClientsForUser', {
    params: {
      userName: data
    }
  })
    .then(response =>
    {
      return response.data;
    })
    .catch(error =>
    {
      return { success: false, data: error };
    });
}


//export async function get(payload) {
//  return axios
//    .get(urlPrefix + "/api/get", {
//      params: {}
//    })
//    .then(response => {
//      return response.data;
//    })
//    .catch(error => {
//      console.log("Error " + error);
//      return error;
//    });
//}
//export async function post(data) {
//  return axios
//    .post(urlPrefix + "/api/", data)
//    .then(response => {
//      return response.data;
//    })
//    .catch(error => {
//      console.log("error" + error);
//      return error;
//    });
//}
//export async function postQueryString(payload) {
//  return axios
//    .post(urlPrefix + "/api/", null, {
//      params: {}
//    })
//    .then(response => {
//      return response.data;
//    })
//    .catch(error => {
//      return error;
//    });
//}
