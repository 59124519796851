import Callback from "../components/Security/callback";
import Help from "../components/Helpdesk/help"
export const routes = [
  {
    name: "Help",
    path: "/",
    icon: "home",
    title: "Help",
    component: Help,
    onNavBar: false,
    meta: {
      requiresAuth: false
    },
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
  } 
];
