<template>
  <div>
    <v-row justify="center">
      <v-expansion-panels accordion>
        <panel v-for="item in list" :key="item.title" :title="item.title" :content="item.content" :video="item.video"></panel>
      </v-expansion-panels>
      </v-row>
    </div>
</template>

<script>
  import Panel from './panel'
  export default {
    props: ['list'],
    components: {
      'panel': Panel
    }
  }
</script>
