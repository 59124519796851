<template>
        <v-expansion-panel >
          <v-expansion-panel-header class="expansionHeader">{{title}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="5">
                <video width="450px" height="auto" controls>
                  <source :src=video type="video/mp4">
                  Your browser does not support the video tag.
                </video>
              </v-col>

              <v-col cols="6">
                <p v-for="p in content" :index="p.text" class="expansionText">{{p.text}} </p>
              </v-col>
            </v-row>
          
          </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
  export default {
    props: ['title', 'content', 'video']
  }
</script>

