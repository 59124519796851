<template>
  <div class="mt-3">
    <v-row style="background-color: #ffcccb">
      <v-icon medium color="red" class="pl-12">mdi-alert-circle-outline</v-icon>
      <p class="red--text pt-3 pl-1" style="font-size: 14px">
        Important: Omni User Portal apps do not support Internet Explorer. Use
        Chrome, Firefox, Edge, Opera, or Safari to browse our apps.
      </p>
    </v-row>
    <v-row class="my-1"></v-row>
    <v-row style="background-color: #BBDEFB">
      <v-icon medium color="blue darken-4" class="pl-12">mdi-information-outline</v-icon>
      <p class="blue--text text--darken-4 pt-3 pl-1" style="font-size: 14px">
        If you cannot find the answer to your question below, please email
        support@omniuserportal.com.
      </p>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-select
          class="mt-10"
          :items="topics"
          label="Select a help topic"
          color="darkgray"
          v-model="topic"
          item-color="blue"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <panel-list :list="list"></panel-list>
  </div>
</template>

<script>
import PanelList from "./panel-list";

export default {
  components: {
    "panel-list": PanelList,
  },
  data: function () {
    return {
      topic: "Profile and account credentials",
      topics: [
        "Profile and account credentials",
        "General",
        "Secure Messaging System",
      ],
      profileList: [
        {
          title: "How to activate your account",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/AccountActivation.mp4",
          content: [
            {
              text: "Log in to your personal email account and find the email from Omni User Management.",
            },
            {
              text: "This email contains your login credentials for your new account at Omni User Portal. Take note of the username and password provided. Then, click on the 'Activate my account' button. You will not be able to log into your account until you have activated it via this link.",
            },
            {
              text: "When you click on this link, you'll be taken to a confirmation page on the Omni User Portal dashboard. Press confirm to complete your account activation.",
            },
            {
              text: "You can now proceed to login to the dashboard using the credentials provided in the email.",
            },
          ],
        },
        {
          title: "How to log in",
          video: "http://helpdesk.omniuserportal.com/assets/videos/Login.mp4",
          content: [
            {
              text: "In every app that you visit from Omni User Portal, there is an account menu on the top right of the toolbar. This menu contains shortcuts to several important functions.",
            },
            {
              text: "The third button, with the 'login / logout' icons, can be used for logging in and out of your applications.",
            },
            {
              text: "Press that button to log in. Input the username and password provided, and press login. You'll now be rerouted back to the application.",
            },
          ],
        },
        {
          title: "How to update your profile",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/UpdateProfile.mp4",
          content: [
            {
              text: "To edit your personal details, navigate to the profile page from the dashboard menu. Click on 'Edit Profile' to change any details or preferences. ",
            },
            {
              text: "Note that certain fields, such as your user name, cannot be changed.",
            },
            {
              text: "You can change your first name, last name, email, phone number, and display name. You can also choose how you'd like to receive alerts and notifications: via an email, text message, or both. You also have the option to provide a secondary email address, if you'd like to receive alerts at more than one address. ",
            },
            { text: "Press 'Save Changes' to update your profile." },
          ],
        },
        //    {
        //  title: 'How to update your profile',
        //  video: "http://helpdesk.omniuserportal.com/assets/videos/UpdateProfile.mp4",
        //  content: [
        //    { text: '' },
        //    { text: '' },
        //    {text: ''},
        //{text: ''}
        //  ]
        //},
        {
          title: "How to reset your password",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/ResetPassword.mp4",
          content: [
            {
              text: "To reset your password, navigate to the profile page on the dashboard menu. Press 'Reset Password.' This will send a password reset link to your email address.",
            },
            {
              text: "Open the password reset email, and press the 'Reset Password' button. You'll be rerouted to a password reset form. Enter your new password, making sure that it meets all the security requirements. Then press submit.",
            },
          ],
        },
        {
          title: "Forgot my username",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/RecoverUsername.mp4",
          content: [
            {
              text: "Forgot your username? Click on the username link on the login screen. You will be rerouted to the Username Recovery link. Enter your email address and press the button. If there is an active account associated with that email address, you will receive an email containing your username.",
            },
          ],
        },
        {
          title: "Forgot my password",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/ForgotMyPassword.mp4",
          content: [
            {
              text: "Forgot your password? Click on the password link on the login screen. You will be rerouted to the Password Recovery page. Enter your email address and press the button. If there is an active account associated with that email address, you will receive an email with a password recovery link.",
            },
            {
              text: "Open the password recovery email, and click on the button. You will be rerouted to the New Password page. Input a new password, and press the button. You can now login with your new password.",
            },
          ],
        },
      ],
      generalList: [
        {
          title: "How to send feedback to the software team",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/Feedback.mp4",
          content: [
            {
              text: "We welcome and appreciate all feedback: from bug reports to feature requests, we want to hear from you. In any app, you can provide feedback by opening the account menu in the top right corner. \n\nThe second button, with the comment icon, will launch a feedback form.",
            },
            {
              text: "The feedback form will automatically include a screenshot of whichever page of the app you were on. This will help us see exactly what issue you're describing. Fill in the fields, and then press the 'Send Feedback' button.",
            },
            {
              text: "We look forward to collaborating with you to make Omni User Portal apps as user friendly and feature rich as we can.",
            },
          ],
        },
        {
          title: "How to view and launch your apps",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/AppTiles.mp4",
          content: [
            {
              text: "When you are logged into the dashboard, the main view will display all of your available apps. You can also access your list of apps by navigating to the apps page from  the dashboard menu.  Each application will appear as a separate tile; double click on an app to launch it.",
            },
            {
              text: "Within any other Omni app, you can always access your dashboard, and your main app tile page, by opening the account menu in the top right corner of the toolbar. The first icon, with the home icon, will launch your dashboard in a new window.",
            },
          ],
        },
      ],
      messagingSystemList: [
        {
          title: "Viewing your inbox",
          video: "http://helpdesk.omniuserportal.com/assets/videos/Inbox.mp4",
          content: [
            {
              text: "Your messaging app has three categories for messages. Your Inbox contains conversations in progress. At the top of the inbox is a little refresh icon; you can press that to refresh your inbox view and retrieve new messages in real time. Conversations with unread messages are indicated by bold formatting. The Sent tab contains all the messages that you have sent, and the Drafts tab contains messages that you have composed, but haven't yet sent.",
            },
          ],
        },
        {
          title: "New message notifications",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/NewMessageAlerts.mp4",
          content: [
            {
              text: "When you log in to your dashboard, you'll notice an alert over the Secure Messaging app tile indicating if you have any new messages. When you receive a new secure message, you'll also receive a notification, either to your email, mobile phone, or both, depending on your alert preferences.",
            },
            {
              text: "You can set your notification  preferences in the Profile page on the dashboard menu. ",
            },
          ],
        },
        {
          title: "Composing a message",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/ComposeMessage.mp4",
          content: [
            {
              text: "To compose and send a secure message, press the Compose tab in the navigation menu. First choose your recipients. Start typing the name of your intended recipient in the recipients box; a list of all matching names will appear for you to choose from.",
            },
            {
              text: "Input your subject line and content. You can also upload an attachment by pressing the paperclip icon.",
            },
            {
              text: "Then, you can press send to deliver the message. If you do not wish to send this message immediately, you can simply press the 'X' button on top. The message will be saved to your drafts. Alternatively, you can press the little trash icon to discard the message.",
            },
          ],
        },
        {
          title: "Searching your inbox",
          video: "http://helpdesk.omniuserportal.com/assets/videos/Search.mp4",
          content: [
            {
              text: "Searching your inbox is easy: simply input the desired text and press search. You can search for matching text in a sender address, recipient address, subject line, or message content.",
            },
          ],
        },
        {
          title: "Downloading an attachment",
          video:
            "http://helpdesk.omniuserportal.com/assets/videos/DownloadAttachment.mp4",
          content: [
            {
              text: "When viewing an email with an attachment, you can easily download the file. Simply hover over the attachment, and press download. The file will immediately download to your drive.",
            },
          ],
        },
      ],
    };
  },
  computed: {
    list: function () {
      switch (this.topic) {
        case "Profile and account credentials":
          return this.profileList;
        case "General":
          return this.generalList;
        case "Secure Messaging System":
          return this.messagingSystemList;
      }
    },
  },
};
</script>
